/**
 * Created by soundview on 4/29/15.
 */
var View = require('views/base/collection-view');
var Model = require('../model');
var Collection = require('../collection');
var ItemView = require('./view');
var BaseModel = require('models/base/model');
var utils = require('lib/utils');

var endAnimation = function (elem, duration) {


	return elem.animate({
		opacity: 1
	}, duration).removeClass('rubberBand animated');


};


var ClientIndex = View.extend({
	autoRender: true,
	listSelector: '#clientList',
	className: 'box',
	id: 'app-content-body',
	itemView: ItemView,
	currentStatus: 'all',
	transition: true,
	useCssAnimation: false,
	animationStartClass: 'rubberBand animated',
	animationEndClass: '',
	fallbackSelector: '.no-data',
	template: require('./templates/collection-view'),
	events: {
		'click .next-page': 'nextPage',
		'click .prev-page': 'prevPage',
		'click .status_nav': 'filterStatus',
		'click .back_btn': '_backPath'
		//'scroll': 'checkScroll'
	},
	listen: {
		'reset collection': 'toggleActive',
		'dispose': 'beforeDispose'
	},
	statuses: {
		0: {label: 'All', name: 'all', status_id: 0},
		1: {label: 'Draft', name: 'draft', status_id: 1},
		2: {label: 'Sent', name: 'sent', status_id: 2},
		3: {name: 'approved', status_id: '3', label: 'Approved'},
		4: {name: 'rejected', status_id: '4', label: 'Rejected'},
		5: {name: 'canceled', status_id: '5', label: 'Canceled'}
	},
	bindings: {
		'#currentPage': {
			observe: 'current_page'
		},
		'#totalPages': {
			observe: 'total_pages'
		}
	},
	initialize: function (options) {

		_.bindAll(this, 'render', 'afterRender');
		var _this = this;
		this.render = _.wrap(this.render, function (render) {
			render();
			_this.afterRender();
			return _this;
		});

		console.log(utils.getCookie('accessToken'));

		this.collection = new Collection();

		this.collection.fetch({reset: true});
		this.listenTo(this, 'dispose', this.beforeDispose);
		this.listenTo(this.collection, 'destroy', this.refresh);

		this.listenTo(this.collection, 'pageChange', this.onPageChange);
		this.listenTo(this.collection, 'stateParsed', this.onStateParse);
		this.model = new Chaplin.Model();

		this.model.set('current_page', this.collection.state.currentPage);
		this.model.set('total_pages', this.collection.totalPages);
		console.log(this.collection);

		this.listenTo(this.collection, 'toView', this.toView);
		//this.collection.models = this.clients.models;

		//this.clients.fetch({reset:true});

		//this.listenTo(this.collection, "reset", this.togglePager);
		ClientIndex.__super__.initialize.call(this, options);
	},
	beforeDispose: function () {
		console.log('testing-bfore-dispose');
	},
	testMediator: function(data){
		console.log(data);
	},
	onStateParse: function(state, collection){
		console.log(state);
		this.model.set('total_pages', state.totalPages);
	},
	nextPage: function () {
		if (this.collection.hasNextPage()) {
			this.collection.getNextPage();
			this.collection.trigger('pageChange', this);
		}
	},
	prevPage: function () {
		if (this.collection.hasPreviousPage()) {
			this.collection.getPreviousPage();
			this.collection.trigger('pageChange', this);
		}
	},
	onPageChange: function() {
		this.model.set('current_page', this.collection.state.currentPage);
	},
	filterStatus: function (e) {
		var status, $target;

		$target = $(e.currentTarget);
		status = $target.data('status');

		this.collection.queryParams.status = status;
		this.currentStatus = status;
		this.collection.fetch({reset: true});

	},
	toView: function (model) {
		Chaplin.utils.redirectTo('client#show', {clientId: model.attributes.id});
	},
	_backPath: function () {
		window.history.back();
	},
	toggleActive: function () {
		var _this = this;
		var currentStatus = this.currentStatus;
		var navs = this.$el.find('.status_nav');
		navs.each(function () {
			console.log(currentStatus);
			//console.log($(this).data('status'));
			//console.log($(this).data('status') == currentStatus);
			$(this).data('status') == currentStatus ? $(this).addClass('active') : $(this).removeClass('active');
			//console.log($(this).data('status'));

		});
		//console.log(navs);
	},
	togglePager: function () {
		//var _this = this;
		//	this.$el.find('.next-page').toggleClass('disabled', this.collection.hasNextPage());
		//	this.$el.find('.prev-page').toggleClass('disabled', this.collection.hasPreviousPage());

	},
	refresh: function() {
		this.collection.fetch({reset: true});
	},
	afterRender: function() {

		this.stickit();
		console.log(this.collection.state);
		this.model.set('current_page', this.collection.state.currentPage);
		this.model.set('total_pages', this.collection.totalPages);

		//this.$('#quotesTable').floatThead();
	}
});

module.exports = ClientIndex;
