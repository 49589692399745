var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "\n	<div class=\"md-no-style md-list-item-inner attr-container hide\">\n		<span>";
  if (helper = helpers.label) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.label); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span>\n		<h3 class=\"text-md\">";
  if (helper = helpers.text) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.text); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</h3>\n	</div>\n	<div class=\"md-no-style md-list-item-inner edit-container\">\n		<div flex=\"25\" class=\"form-group\">\n			<input type=\"text\" class=\"form-control input__label\" name=\"label\" value=\"";
  if (helper = helpers.label) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.label); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" >\n			<label>Label</label>\n		</div>\n		<div class=\"padder\" flex>\n			<div class=\"form-group\">\n				<input type=\"text\" class=\"form-control input__text\" name=\"text\" value=\"";
  if (helper = helpers.text) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.text); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n				<label>Value</label>\n			</div>\n		</div>\n		<input type=\"hidden\" name=\"item_id\" class=\"input__item_id\" value=\"";
  if (helper = helpers.item_id) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.item_id); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n		<div class=\"actions\">\n			<a class=\"md-icon-button md-button md-default-theme blue save-btn\" tabindex=\"0\">\n				<i class=\"mdi-navigation-check i-20\"></i>\n			</a>\n			<a class=\"md-icon-button md-button md-default-theme attr-delete-btn\"\n			   tabindex=\"0\">\n				<i class=\"mdi-navigation-close i-20\"></i>\n			</a>\n\n		</div>\n	</div>\n\n\n\n";
  return buffer;
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}