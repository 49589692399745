/**
 * Created by soundview on 3/6/15.
 */

var Controller = require('controllers/base/controller');
var HeaderView = require('views/layout/header-view');
var ClientView = require('views/client-view');
var HomePageView = require('views/home/home-page-view');
var TestPageView = require('views/home/test-page-view');
var AsideLeftView = require('views/layout/sidebar-view');
var ClientList = require('views/client/clients');

var WindowQuoteCollection = require('window/collection');
var GlassQuoteCollection = require('models/quote-collection');
var InsulationQuoteCollection = require('models/insulation-quote-collection');

var ClientModel = require('modules/clients/model');
var ClientIndex = require('modules/clients/index/collection-view');
var ClientView = require('modules/clients/show/view');

var LayoutController = require('controllers/layout-controller');


module.exports = LayoutController.extend({
	beforeAction: function () {
		//this.constructor.__super__.beforeAction.apply(this, arguments);
		LayoutController.prototype.beforeAction.call(this, arguments);
		//this.reuse('header', HeaderView, {region: 'header'});
		//this.reuse('asideLeft', AsideLeftView, {region: 'asideLeft'});
	},

	index: function () {
		this.view = new ClientIndex({region: 'content'});
	},
	show: function(params) {
		this.model = new ClientModel({id: params.clientId});
		this.quotes = {
			windows: new WindowQuoteCollection(),
			glass: new GlassQuoteCollection(),
			insulation: new InsulationQuoteCollection()
		};


		return this.model.fetch().then((function (_this) {
			return function () {
				//_.each(_this.quotes, function(col){
				//	col.fetch({data: $.param({clientId: params.clientId})});
				//});
				_this.quotes.windows.fetch({
					data: {client_id: params.clientId}
				});
				_this.quotes.glass.fetch({
					data: {client_id: params.clientId}
				});
				_this.quotes.insulation.fetch({
					data: {client_id: params.clientId}
				});

				_this.view = new ClientView({model: _this.model, quotes: _this.quotes, region: 'content'});

			};
		})(this));
		//this.view = new ClientView()
	},

	test: function () {
		this.view = new TestPageView({region: 'main'});
	}


});