var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";


  buffer += "<header class=\"navbar md-whiteframe-z1 no-radius bg-white\" id=\"header-view\">\n	<ul class=\"nav navbar-tool pull-left\">\n		<li>\n			<a href=\"#\" class=\"back_btn waves-effect\">\n				<i class=\"mdi-navigation-arrow-back i-24\"></i>\n			</a>\n		</li>\n	</ul>\n	<div class=\"navbar-item h4 pull-left\">\n		Window Install Quotes\n	</div>\n	<div class=\"navbar-item navbar-btns pull-right\">\n\n		<a href=\""
    + escapeExpression((helper = helpers.url || (depth0 && depth0.url),options={hash:{},data:data},helper ? helper.call(depth0, "window#create", options) : helperMissing.call(depth0, "url", "window#create", options)))
    + "\" class=\"md-btn md-flat md-flat-dk waves-effect create-quote-btn\">Create\n			Quote</a>\n\n\n		<a class=\"md-btn md-flat md-flat-dk md-btn-icon waves-effect refresh-btn\" data-toggle=\"tooltip\"\n		   data-placement=\"bottom\"\n		   data-title=\"Refresh\" data-original-title=\"\" title=\"\"><i class=\"m-d-refresh i-20\"></i></a>\n\n	</div>\n	<ul class=\"nav navbar-tool nav-pills pull-right navbar-right x-toolbar mr\" id=\"statusNav\">\n\n		<li class=\"status_nav status_all\" data-status=\"all\">\n			<a href=\"#\">All</a>\n		</li>\n		<li class=\"status_nav status_draft\" data-status=\"draft\">\n			<a href=\"#\">Draft</a>\n		</li>\n		<li class=\"status_nav status_sent\" data-status=\"sent\">\n			<a href=\"#\">Sent</a>\n		</li>\n		<li class=\"status_nav status_approved\" data-status=\"approved\">\n			<a href=\"#\">Approved</a>\n		</li>\n		<li class=\"status_nav status_rejected\" data-status=\"rejected\">\n			<a href=\"#\">Rejected</a>\n		</li>\n		<li class=\"status_nav status_canceled\" data-status=\"canceled\">\n			<a href=\"#\">Canceled</a>\n		</li>\n	</ul>\n\n\n	<!--<div class=\"collapse pos-rlt navbar-collapse bg-light b-b\">-->\n	<!--<ul class=\"nav navbar-nav hidden-xs m-l-n mr15\">-->\n	<!--<li>-->\n	<!--<a href=\"#\" class=\"back_btn\">-->\n	<!--<i class=\"mdi-navigation-arrow-back\"></i>-->\n	<!--</a>-->\n	<!--</li>-->\n	<!--</ul>-->\n	<!--<div class=\"navbar-header\">-->\n	<!--<span class=\"navbar-brand\">-->\n	<!--<span>Quotes</span>-->\n	<!--</span>-->\n\n	<!--</div>-->\n\n	<!--";
  if (helper = helpers.statuses) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.statuses); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "-->\n\n\n	<!---->\n	<!--</div>-->\n\n</header>\n\n\n<div class=\"box-row\">\n	<div class=\"box-cell\">\n		<div class=\"box-inner\">\n\n					<div class=\"wrapper bg-white-only\">\n						<table class=\"table items table-hover fixed-header\" fixed-header id=\"clientsTable\">\n\n							<thead>\n							<tr>\n								<!--<th class=\"status\"><span>STATUS</span></th>-->\n								<th><span>CLIENT</span></th>\n								<th><span>EMAIL</span></th>\n								<th><span>CREATED/UPDATED</span></th>\n								<th><span>CLIENT</span></th>\n								<th class=\"amount num\" style=\"text-align: right; padding-right: 25px;\">\n									<span>BALANCE</span></th>\n								<th class=\"x-field-action\"><span></span></th>\n							</tr>\n							</thead>\n\n							<tbody id=\"clientList\">\n\n							</tbody>\n\n\n						</table>\n					</div>\n\n\n		</div>\n	</div>\n</div>\n<footer id=\"quoteFooter\" class=\"footer bg-white b-t\" layout=\"row\">\n	<div flex>\n		<a class=\"md-btn pull-left md-btn-icon md-flat md-flat-dk waves-effect mt10 prev-page\">\n			<i class=\"mdi-navigation-chevron-left i-20\"></i>\n		</a>\n	</div>\n	<div flex>\n		<div style=\"width:100%;\" class=\"text-center page-number pt15\">\n			Page:\n			<span class=\"\" id=\"currentPage\"></span>\n			<span>of</span>\n			<span class=\"\" id=\"totalPages\"></span>\n\n\n\n		</div>\n	</div>\n	<div flex>\n		<a class=\"md-btn pull-right md-btn-icon md-flat md-flat-dk waves-effect mt10 next-page\">\n			<i class=\"mdi-navigation-chevron-right i-20\"></i>\n		</a>\n	</div>\n\n\n\n</footer>\n\n\n";
  return buffer;
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}