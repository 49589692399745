// Application routes.
module.exports = function(match) {
	//match('', 'quote#dashboard');
	//match('index.php/quotes', 'quote#index');

	match('clients', 'client#index');
	match('clients/:clientId', 'client#show');
	match('quotes', 'quote#index');
	match('w_quotes', 'window#index');
	match('quote/create', 'quote#create');
	match('w_quote/create', 'window#create');
	match('quotes/:quoteId', 'quote#show');
	match('w_quotes/:quoteId', 'window#show');
	match('w_item/create', 'window#createItem');
	match('w_item/:itemId', 'window#edit');
	match('item/:itemId', 'quote#edit');
	match('item/create', 'quote#createItem');
	match('insulation', 'insulation#index');
	match('insulation/create', 'insulation#create');
	match('insulation/:quoteId', 'insulation#show');
	match('insulation/item/create', 'insulation#createItem');
	return match('', 'window#index');
};

