/**
 * Created by soundview on 3/9/15.
 */
var View = require('views/base/view');
var Model = require('../model');
var DateTimeFormatter = require('lib/formatter/datetime');
$.fn.datepicker.defaults.format = 'yyyy-mm-dd';


module.exports = View.extend({
	autoRender: true,
	container: '#info-panel',
	//container: '#info-panel',
	tag: 'div',
	className: 'vbox wrapper',
	//region: 'info',
	template: require('./templates/info'),
	statuses: [{
		value: 1,
		label: 'Draft'
	}, {
		value: 2, label: 'Sent'
	},
		{value: 3, label: 'Approved'},
		{value: 4, label: 'Rejected'},
		{value: 5, label: 'Canceled'}
	],
	events: {
		'click .add_tax_rate': 'addTaxRate',
		'click .delete_tax_rate': 'removeTaxRate',
		'click .edit-client-btn': '_editClient'
	},
	bindings: {
		'#user_id': {
			observe: 'user_id',
			events: ['blur'],
			setOptions: {silent: true},
			selectOptions: {
				collection: 'window.users',
				labelPath: 'name',
				valuePath: 'id'
			},
			onSet: function (val) {
				//var d = DateTimeFormatter.convertDateToBackendFormat(val);
				this.model.set("user_id", val);
				this.saveAttr();
			}
		},
		'#created_at': {
			observe: 'created_at',
			events: ['blur'],
			initialize: function($el) {
				$el.datepicker();
			},
			//onGet: function(val) {
			//	return DateTimeFormatter.formatDate(val);
			//},
			onSet: function (val) {
				//var d = DateTimeFormatter.convertDateToBackendFormat(val);
				this.model.set("created_at", val);
				this.saveAttr();
			},
			afterUpdate: function ($el) {
				//$el.prop('disabled', true);
			}
		},
		'#so_info': {
			observe: 'quote_status_id',
			visible: function(val, options) {
				return val === 3;
			}
		},
		'#so_number': {
			observe: 'so_number',
			events: ['blur'],
			onSet: function (val) {
				this.model.set('so_number', val);
				this.saveAttr();
				return val;
			}
		},
		'#date_ordered': {
			observe: 'date_ordered',
			events: ['blur'],
			initialize: function ($el) {
				$el.datepicker();
			},
			//onGet: function (val) {
			//	return DateTimeFormatter.formatDate(val);
			//},
			onSet: function (val) {
				//var d = DateTimeFormatter.convertDateToBackendFormat(val);
				console.log(val);
				this.model.set('date_ordered', val);
				this.saveAttr();
				return val;
			}
		},
		'#quote_status': {
			observe: 'quote_status_id',
			initialize: function ($el) {
				$el.select2({
					width: "100%", placeholder: 'Select Status...', allowClear: true,
					minimumResultsForSearch: Infinity
				});
			},
			selectOptions: {
				collection: function () {
					return this.statuses;
				},
				valuePath: 'value',
				labelPath: 'label'
				//defaultOption: {
				//	label: 'Select Service..',
				//	value: null
				//}
			},
			onSet: function (val) {
				this.model.set('quote_status_id', val);
				this.saveAttr();
				return val;
			}
		},
		'#expires_at': {
			observe: 'expires_at',
			events: ['blur'],
			initialize: function ($el) {
				$el.datepicker();
			},
			//onGet: function (val) {
			//	return DateTimeFormatter.formatDate(val);
			//},
			onSet: function (val, options) {
				//var d = DateTimeFormatter.convertDateToBackendFormat(val);
				this.model.set("expires_at", val);
				this.saveAttr();
				//this.model.trigger('save', this.model);
			},
			afterUpdate: function ($el) {
				//$el.prop('disabled', true);
			}
		}
	},
	initialize: function (options) {
		this.model = options.model;
		console.log(this.model);
		//this.listenTo(this.model, 'change', this.reload);
		//this.model.fetch();

		if(!window.users){
			var users = $.ajax({
				async: false,
				url: "/index.php/api/v1/users",
				success: function (data) {
					return data;
				}
			});
			console.log('syncing users');
			window.users = users.responseJSON;
		}

		//console.log(this.model.attributes);
		//this.collection = options.collection || new Collection();
		//this.collection.url = '/index.php/api/clients';

		//this._initMainContainers();
		//this.listenTo(this.getCollection(), 'reset', this.renderClientList);

		//this.getCollection().fetch({reset: true});
		//this.listenTo(this.model, 'change:amount', this.reload);

	},
	initComponents: function() {

	},
	reload: function() {
		this.model.fetch({
			wait: true,
			success: _.bind(function () {
				console.log(this.model);
				var self = this;
				_.delay(function () {
					self.render();
				}, 800);
			}, this)
		});

		console.log('reload info-view');
		this.render();
	},
	saveAttr: function () {
		//console.log(val);
		if (!_.isEmpty(this.model.changed)) {
			this.model.save(this.model.changed, {
				patch: true,
				success: function(resp) {
					console.log(resp);
				}
			});
		}
		//console.log(this.model.attributes);
	},
	_editClient: function() {
		this.model.trigger('editClient', this.model);
	},
	getTemplateData: function() {
		var data = this.model.serialize();
		var taxRates = [
			{name: 'WA SALES', tax_rate_id: 1, active: true},
			{name: 'CC FEE', tax_rate_id: 2, active: false},
			{name: 'FINANCE FEE', tax_rate_id: 3, active: false}
		];
		var _this = this;
		_.each(taxRates, function(rate){
			rate.active = _.find(_this.model.get('tax_rates'), function (item) {
				return  parseInt(item.tax_rate_id) === parseInt(rate.tax_rate_id);
				//console.log(item);
			});
		});

		data.__proto__.taxRates = taxRates;


		return data;
	},
	addTaxRate: function(e){
		var $this = $(e.target);
		$this.is('a') || ($this = $this.closest('a'));
		var tax_rate_id = $this.data('tax-rate');
		var quote_id = this.model.get('id');

		var _this = this;
		$.post("/index.php/add_fee/"+quote_id, {
			quote_id: quote_id,
			tax_rate_id: tax_rate_id
		}).done(function (response) {
			_this.model.trigger('updated', this);
		}).fail(function (response) {
			if (response.status == 400) {
				showErrors($.parseJSON(response.responseText).errors, '#modal-status-placeholder');
			} else {
				alert("UNKNOWN ERROR");
			}
		});
		console.log($this.data('tax-rate'));
	},
	removeTaxRate: function (e) {
		var $this = $(e.target);
		$this.is('a') || ($this = $this.closest('a'));
		var _this = this;
		var tax_rate_id = $this.data('tax-rate');
		var quote_tax_rate = _.find(_this.model.get('tax_rates'), function (item) {
			return parseInt(item.tax_rate_id) === parseInt(tax_rate_id);
			//console.log(item);
		});

		console.log(quote_tax_rate);


		var quote_id = this.model.get('id');


		$.post("/index.php/remove_fee/" + quote_id, {
			id: parseInt(quote_tax_rate.id)
		}).done(function (response) {
			_this.model.trigger('updated', this);
		}).fail(function (response) {
			if (response.status == 400) {
				showErrors($.parseJSON(response.responseText).errors, '#modal-status-placeholder');
			} else {
				alert("UNKNOWN ERROR");
			}
		});
		console.log($this.data('tax-rate'));
	},
	render: function(){


		//var data = this.model.toJSON();
		////console.log(data);
		//
		//console.log(DateTimeFormatter.unformatDate(this.model.get('expires_at')));
		//
		//this.$el.append(this.template(data));

		View.__super__.render.apply(this, arguments);
		this.$el.find('.dp').datepicker({
			prevText: '<i class="fa fa-angle-left"></i>',
			nextText: '<i class="fa fa-angle-right"></i>'
		});

		this.stickit();

		return this;
	}



});

